export const optionCategoryStatus = [
  {
    label: "Inactive",
    value: 0,
    backgroundColor: "#FAFAFA",
    textColor: "#616161",
  },
  {
    label: "Active",
    value: 1,
    backgroundColor: "#E8F5E9",
    textColor: "#388E3C",
  },
];
